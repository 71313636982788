import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input/input";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import {
  userRegister,
  AccessToken,
  SetApplicantIdaction,
  SetOtpMail,
  SaveMailId,
} from "../actions/axioss/user.axios";
import config from "../config/config";
import { toast } from "react-toastify";
import { isEmpty } from "../actions/common";
import { getCmsContent } from "../actions/axioss/cms.axios";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";

// Generate a 6-digit random number

function KYCActivate({ show, userProfile, getProfileDetails, handleClose }) {
  console.log("userProfile", userProfile);
  const dispatch = useDispatch();
  const { payload, token, gasFee } = useSelector(
    (state) => state.LoginReducer.User
  );
  const [selectedOption, setSelectedOption] = useState(null);
  const [value, setValue] = useState();
  const options = useMemo(() => countryList().getData(), []);
  const [Profile, setUserProfile] = useState(userProfile);
  const [Error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(false);
  const [selectkyctype, setSelectkyctype] = useState("SumSub Kyc");
  const [otp, setOtp] = useState("");
  const [userInputOtp, setUserInputOtp] = useState("");
  const [expiryTime, setExpiryTime] = useState(null);
  const [timeLeft, setTimeLeft] = useState(0);
  const [error, seterror] = useState("");
  const [MailAdd, setMailAdd] = useState("");
  const [MailSent, setMailSent] = useState(false);
  const [ConfirmOtp, setConfirmOtp] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [HideShow, setHideShow] = useState(false);
  const [Details, setDetails] = useState({});
  const [changemail, setchangemail] = useState("Mail");
  const desc = [
    {
      descText:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem",
    },
  ];

  const kycOptions = [
    { value: "USD", label: "USD" },
    { value: "ETH", label: "ETH" },
    { value: "BNB", label: "BNB" },
    { value: "WETH", label: "WETH" },
    { value: "WEETH", label: "WEETH" },
  ];

  const KycTypes = [
    { value: "Mannual Kyc", label: "Mannual Kyc" },
    { value: "SumSub Kyc", label: "SumSub Kyc" },
  ];
  const stylesgraybgOne = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#16EBC3"
          : isSelected
          ? "#16EBC3"
          : isFocused
          ? "#16EBC3"
          : "#151515",
        cursor: "pointer",
        color: isHovered
          ? "#000"
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : "#fff",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "49px",
      width: "40px",
      backgroundColor: isHovered
        ? "transparent"
        : isSelected
        ? "transparent"
        : isFocused
        ? "transparent"
        : "transparent",
      border: "2px solid #16ebc3",
      borderRadius: 5,
      fontSize: "13px",
      color: "#fff",
    }),
    control: (provided, { isFocused, isSelected, isHovered }) => ({
      ...provided,
      height: "40px",
      width: "100%",
      borderRadius: 5,
      backgroundColor: isHovered
        ? "#1D1D1D4F"
        : isSelected
        ? "#1D1D1D4F"
        : isFocused
        ? "#1D1D1D4F"
        : "#1D1D1D4F",
      // backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
      color: "#16EBC3",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      width: "40px",
      position: "absolute",
      right: 0,
      top: "5px",
      color: "#16EBC3",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  console.log("value", value);

  const handleChange = (e) => {
    setError({});
    e.preventDefault();
    console.log("errrrr on 0", e.target);
    const { id, value, files } = e.target;
    let formdata = { ...Profile, ...{ [id]: files ? files[0] : value } };
    setUserProfile(formdata);
    console.log("formdata", Profile);
  };

  console.log("Profileee", Profile);

  const validation = () => {
    const error = {};

    // if (!config.EMAIL.test(Profile?.EmailId)) error.EmailId = "PLease Enter valid email address";
    // if (!Profile?.EmailId) error.EmailId = "Enter email address";
    if (!Profile?.Address) error.Address = "Enter Address";
    if (!isValidPhoneNumber(Profile?.mobileNumber))
      error.mobileNumber = "Enter valid mobile number";
    if (!Profile?.mobileNumber) error.mobileNumber = "Enter mobile number";
    if (!Profile?.Nationality) error.Nationality = "Enter Nationality";
    if (!Profile?.kycFile) error.kycFile = "Select kyc file";
    if (!Profile?.Name) error.kycFile = "Enter Name";
    if (!Profile?.SurName) error.kycFile = "Enter Surname";

    return error;
  };

  const onSubmit = async () => {
    if (!Profile?.termsAccepted)
      return toast.error("Please accept terms and conditions");
    const validate = validation();
    if (!isEmpty(validate)) return setError(validate);
    const id = toast.loading("Kyc updating");
    setLoading(true);
    Profile.Type = "KYC";
    const Resp = await userRegister(Profile);
    console.log("RespRespResp", Resp);
    if (Resp?.success == "success") {
      toast.update(id, {
        render: Resp?.msg,
        isLoading: false,
        autoClose: 1000,
        type: "success",
      });
      setTimeout(() => {
        handleClose();
        getProfileDetails();
      }, 1000);
    } else
      toast.update(id, {
        render: Resp?.msg,
        isLoading: false,
        autoClose: 1000,
        type: "error",
      });
  };

  useEffect(() => {
    getCmsList();
  }, []);

  const [kycCon, setKycCon] = useState({});

  const getCmsList = async () => {
    const Resp = await getCmsContent({
      page: ["KYC"],
    });
    console.log("sejhfgeiusf", Resp);
    setKycCon(Resp?.data?.[0] ?? {});
  };
  console.log("kycCon", kycCon);

  useEffect(() => {
    createAccessToken();
  }, []);

  const [accessToken, setaccessToken] = useState(
    localStorage.getItem("toksum") ||
      "_act-sbx-jwt-eyJhbGciOiJub25lIn0.eyJqdGkiOiJfYWN0LXNieC1hYWI5NjllNi1lNGEyLTRjMTctOGU5Ni0zZWI3MWVhZjhiNmMtdjIiLCJ1cmwiOiJodHRwczovL2FwaS5zdW1zdWIuY29tIn0.-v2"
  );
  const createAccessToken = async () => {
    try {
      console.log(
        "payload.WalletAddressaddqew",
        localStorage.getItem("accountInfo")
      );
      if (localStorage.getItem("accountInfo")) {
        const wedata = {
          Address: localStorage.getItem("accountInfo")?.toLowerCase(),
          kycType: "individual",
        };
        let token = await AccessToken(wedata);
        console.log(token, "aewqeqeqweq");
        if (token.success) {
          // this.setState({ accessToken: token })
          setaccessToken(token.data);
          localStorage.setItem("toksum", token.data);
          return token.data;
        }
      }
    } catch (err) {
      console.log(err, "createAccessToken___err");
    }
  };

  const SetApplicantId = async (applicantId) => {
    try {
      if (localStorage.getItem("accountInfo")) {
        let data = {
          userId: localStorage.getItem("accountInfo")?.toLowerCase(),
          applicantId: applicantId,
        };
        let result = await SetApplicantIdaction(data);
        if (result) {
          return true;
        }
      }
    } catch (err) {
      console.log(err, "createAccessToken___err");
    }
  };

  function generateFourDigitOTP() {
    // Generate a random number between 1000 and 9999
    return Math.floor(1000 + Math.random() * 9000);
  }

  const generateOTP = async () => {
    const id = toast.loading("Generating OTP");
    const otp = generateFourDigitOTP();
    console.log("Your 4-digit OTP:", otp);
    const currentTime = new Date().getTime();
    console.log("currentTime", currentTime, expiryTime);
    if (!expiryTime) {
      setOtp(otp);
      setDisabled(true);
      const expiration = new Date().getTime() + 2 * 60 * 1000; // 2 minutes expiration
      setExpiryTime(expiration);
      setTimeLeft(2 * 60); // Start countdown
      setUserInputOtp(""); // Reset user input
      seterror(""); // Clear previous errors
      const validator = require("validator");

      const MailValid = validator.isEmail(MailAdd);
      console.log("MailValid", MailValid);
      if (MailValid) {
        const SendData = {
          MailId: MailAdd,
          otp: otp,
        };
        console.log("SendData", SendData);
     
        const SendMail = await SetOtpMail(SendData);
        console.log("SendMail", SendMail);
        if (SendMail.success) {
          toast.update(id, {
            render: SendMail.message,
            isLoading: false,
            autoClose: 1000,
            type: "success",
          });
          // toast.success(SendMail.message);
          setMailSent(true);
          setDisabled(true);
          setDetails(SendMail.data);
        } else {
          toast.update(id, {
            render: SendMail.message,
            isLoading: false,
            autoClose: 1000,
            type: "error",
          });
          // toast.error(SendMail.message);
          setMailSent(false);
          setDisabled(false);
        }
      } else {
        // toast.error("Please enter valid email");
        toast.update(id, {
          render: "Please enter valid email",
          isLoading: false,
          autoClose: 1000,
          type: "error",
        });
        setDisabled(false);
      }
    } else {
      if (currentTime > expiryTime) {
        setOtp(otp);
        setDisabled(true);
        const expiration = new Date().getTime() + 2 * 60 * 1000; // 2 minutes expiration
        setExpiryTime(expiration);
        setTimeLeft(2 * 60); // Start countdown
        setUserInputOtp(""); // Reset user input
        seterror(""); // Clear previous errors
        const validator = require("validator");

        const MailValid = validator.isEmail(MailAdd);
        console.log("MailValid", MailValid);
        if (MailValid) {
          const SendData = {
            MailId: MailAdd,
            otp: otp,
          };
          console.log("SendData", SendData);
      
          const SendMail = await SetOtpMail(SendData);
          console.log("SendMail", SendMail);
          if (SendMail.success) {
            toast.update(id, {
              render: SendMail.message,
              isLoading: false,
              autoClose: 1000,
              type: "success",
            });
            
            // toast.success(SendMail.message);
            setMailSent(true);
            setDisabled(true);
            setDetails(SendMail.data);
          } else {
            toast.update(id, {
              render: SendMail.message,
              isLoading: false,
              autoClose: 1000,
              type: "error",
            });
            // toast.error(SendMail.message);
            setMailSent(false);
            setDisabled(false);
          }
        } else {
          // toast.error("Please enter valid email");
          toast.update(id, {
            render: "Please enter valid email",
            isLoading: false,
            autoClose: 1000,
            type: "error",
          });
          setDisabled(false);
        }
      } else {
        toast.update(id, {
          render: "Try After Time Ends",
          isLoading: false,
          autoClose: 1000,
          type: "error",
        });
        setDisabled(false);
      }
    }
  };

  const handleSubmit = async () => {
    const currentTime = new Date().getTime();
    console.log("currentTime", currentTime, expiryTime, ConfirmOtp, otp);
    // Check if OTP has expired
    if (currentTime > expiryTime) {
      seterror("OTP has expired. Please generate a new one.");
      setDisabled(false);
      return;
    }

    // Check if OTP matches
    if (Number(ConfirmOtp) == Number(otp)) {
      const SaveMail = await SaveMailId({
        MailId: MailAdd,
        userId: localStorage.getItem("accountInfo")?.toLowerCase(),
      });
      console.log("SaveMail", SaveMail);
      if (SaveMail.success) {
        toast.success("OTP verified successfully!");
        localStorage.setItem("EmailID", MailAdd);
        setHideShow(true);
        setDetails({
          ...Details,
          token: localStorage.getItem("toksum"),
        });
        dispatch({
          type: "Register_Section",
          Register_Section: {
            User: {
              payload: Details,
              token: token,
              gasFee: gasFee || {},
            },
          },
        });
      } else {
        setDisabled(false);
        seterror("Invalid OTP. Please try again.");
      }
    } else {
      console.log("asdwewqeqw");
      setDisabled(false);
      seterror("Invalid OTP. Please try again.");
    }
  };

  // Countdown logic
  useEffect(() => {
    if (timeLeft > 0 && MailSent) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer); // Clear interval when component unmounts
    }
  }, [timeLeft, MailSent]);

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className="common_modal kycactivates"
      >
        <Modal.Body>
          <div className="modal_top">
            <div className="kyc_poptop w-100">
              {/* <img src={require('../assets/images/redround.svg').default} /> */}

              <p className="modal_title text-center ">Activate Your KYC</p>
            </div>
            <img
              src={require("../assets/images/close.svg").default}
              id="redCloser"
              onClick={() => handleClose()}
              className="modal_closer"
            />
          </div>
          {console.log(
            "payload?.EmailId",
            payload?.EmailId,
            localStorage.getItem("EmailID")
          )}
          {!HideShow ? (
            <>
              {!localStorage.getItem("EmailID") ? (
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                  <div className="mt_2">
                    <p className="modal_summaryLabel text-start">
                      Email Address
                    </p>
                    <input
                      type="email"
                      className="modal_singleinput mt-2"
                      id="EmailId"
                      onChange={(e) => setMailAdd(e.target.value)}
                      placeholder="test@gmail.com"
                    />
                  </div>

                  <div className="hc-margin_top">
                    <button
                      onClick={generateOTP}
                      className="mint_cnctwallet bodygradientBtn "
                      disabled={disabled}
                    >
                      Generate OTP
                    </button>
                  </div>

                  {MailSent && (
                    <div className="hc-otpinput">
                      <OtpInput
                        className=""
                        value={ConfirmOtp}
                        shouldAutoFocus={true}
                        onChange={(e) => {
                          setConfirmOtp(e);
                        }}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                      />

                      <div className="hc-margin_top">
                        {error && <p className="text-center">{error}</p>}
                      </div>

                      <div className="hc-margin_top">
                        {timeLeft > 0 ? (
                          <p className="text-center">
                            Time left: {timeLeft} seconds
                          </p>
                        ) : (
                          <p className="text-center text-danger">
                            OTP has expired. Generate a new one.
                          </p>
                        )}
                      </div>
                      <div className="hc-margin_top">
                        <button
                          onClick={handleSubmit}
                          className="mint_cnctwallet bodygradientBtn "
                        >
                          Verify OTP
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <h6 className="hc-margin_top" style={{fontSize:"14px"}}>This Your Email Id {localStorage.getItem("EmailID")}</h6>
                  <div className="hc-margin_top d-flex align-items-center gap-4">
                    <button
                      className="mint_cnctwallet bodygradientBtn"
                      onClick={() => setchangemail("")}
                    >
                      Change Mail
                    </button>
                    <button
                      className="mint_cnctwallet bodygradientBtn"
                      onClick={() => setHideShow(true)}
                    >
                      continue
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="modal_body mt_2">
              <div className="hc-mdl_rctslt">
                <Select
                  styles={stylesgraybgOne}
                  // menuIsOpen
                  // className="border_select"
                  classNamePrefix="react_select"
                  options={KycTypes}
                  onChange={(e) => {
                    setSelectkyctype(e.value);
                  }}
                />
              </div>
              {console.log("selectkyctype", selectkyctype)}
              {/* <p className='modal_summaryLabel'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}

              {/* {desc.map((i) => (
              <>
                {description ? ( */}
              <p
                className="hc-kyc__modal-p mt_3"
                dangerouslySetInnerHTML={{ __html: kycCon?.content }}
              ></p>
              {/* ) : (
                  <p className="mp_detailbrief mint_scrollText mt-4">
                    {i.descText.length > 150
                      ? i.descText.slice(0, 150).concat("...")
                      : i.descText}
                  </p>
                )}
              </>
            ))} */}
              {/* <button
              className="mp_readmoreBtn readmore_left mt-2"
              onClick={() => setDescription(!description)}
            >
              {description ? "Read Less" : "Read More"}
            </button> */}
              {selectkyctype !== "SumSub Kyc" ? (
                <>
                  <Row className="mt_1">
                    <Col lg={6} xs={12} className="mb_2">
                      <div className="mt_2">
                        <p className="modal_summaryLabel">First name</p>
                        <input
                          type="text"
                          className="modal_singleinput mt-2"
                          id="Name"
                          disabled={Profile?.KycStatus == "complete"}
                          value={Profile?.Name}
                          onChange={handleChange}
                          placeholder="First name"
                        />
                        {Error?.Name && (
                          <p style={{ color: "red", fontSize: 12 }}>
                            {Error?.Name}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={6} xs={12} className="mb_2">
                      <div className="mt_2">
                        <p className="modal_summaryLabel">Last name</p>
                        <input
                          type="text"
                          className="modal_singleinput mt-2"
                          id="SurName"
                          disabled={Profile?.KycStatus == "complete"}
                          value={Profile?.SurName}
                          onChange={handleChange}
                          placeholder="Last name"
                        />
                        {Error?.SurName && (
                          <p style={{ color: "red", fontSize: 12 }}>
                            {Error?.SurName}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={6} xs={12} className="mb_2">
                      <div className="mt_2">
                        <p className="modal_summaryLabel">Email Address</p>
                        <input
                          type="text"
                          className="modal_singleinput mt-2"
                          id="EmailId"
                          value={Profile?.EmailId}
                          onChange={handleChange}
                          placeholder="test@gmail.com"
                        />
                        {Error?.EmailId && (
                          <p style={{ color: "red", fontSize: 12 }}>
                            {Error?.EmailId}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={6} xs={12} className="mb_2">
                      <div className="mt_2">
                        <p className="modal_summaryLabel">Address</p>
                        <input
                          type="text"
                          className="modal_singleinput mt-2"
                          id="Address"
                          value={Profile?.Address}
                          onChange={handleChange}
                          placeholder="Enter Address"
                        />
                        {Error?.Address && (
                          <p style={{ color: "red", fontSize: 12 }}>
                            {Error?.Address}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col lg={6} xs={12} className="mb_2">
                      <div className="mt_2">
                        <p className="modal_summaryLabel">
                          Telephone (with counrty code)
                        </p>
                        <div className="kyc_phoneInput mt_1">
                          <PhoneInput
                            placeholder="+971 50 1234567"
                            value={Profile?.mobileNumber}
                            onChange={(e) => {
                              setError({});
                              setUserProfile({ ...Profile, mobileNumber: e });
                            }}
                          />
                          {Error?.mobileNumber && (
                            <p style={{ color: "red", fontSize: 12 }}>
                              {Error?.mobileNumber}
                            </p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} xs={12} className="mb_2">
                      <div className="mt_2">
                        <p className="modal_summaryLabel mb_1">Nationality</p>
                        <div className="nations">
                          <Select
                            classNamePrefix={"react_select"}
                            className="border_select"
                            placeholder="Select Project"
                            styles={stylesgraybgOne}
                            options={options}
                            value={{
                              label: Profile?.Nationality,
                              value: Profile?.Nationality,
                            }}
                            onChange={(e) => {
                              setError({});
                              setUserProfile({
                                ...Profile,
                                Nationality: e.label,
                              });
                            }}
                          />
                        </div>
                        {Error?.Nationality && (
                          <p style={{ color: "red", fontSize: 12 }}>
                            {Error?.Nationality}
                          </p>
                        )}
                      </div>
                    </Col>

                    <Col lg={6} xs={12} className="mb_2">
                      <div className="kyc_flex">
                        <div className="kyc_imagewrapper">
                          {Profile?.kycFile == "" ? (
                            <img
                              className="kyc_editImg"
                              src={
                                require("../assets/images/empty.svg").default
                              }
                            />
                          ) : typeof Profile?.kycFile == "string" ? (
                            <img
                              className="img-fluid customed_img"
                              src={`${config.IMG_URL}/user/${Profile?.WalletAddress}/kyc/${Profile?.kycFile}`}
                            />
                          ) : (
                            <img
                              className="img-fluid customed_img"
                              src={URL.createObjectURL(Profile?.kycFile)}
                            />
                          )}
                          <input
                            type="file"
                            className="kyc_fileInput"
                            accept="image/*"
                            id="kycFile"
                            onChange={handleChange}
                          />
                        </div>
                        <div>
                          <p className="modal_summaryLabel">
                            Passport or ID Image
                          </p>
                          <p className="profile_joinDate style_none">
                            PNG,JPG files accepted
                          </p>
                        </div>
                      </div>
                      {Error?.kycFile && (
                        <p style={{ color: "red", fontSize: 12 }}>
                          {Error?.kycFile}
                        </p>
                      )}
                    </Col>
                    <Col lg={6} xs={12}>
                      <Form className="mt_2">
                        <Form.Check
                          type="checkbox"
                          className="mp_customCheck kyc_customcheck"
                          id="buynow"
                        >
                          <Form.Check.Input
                            type="checkbox"
                            isValid
                            checked={Profile?.termsAccepted}
                            onChange={(e) =>
                              setUserProfile({
                                ...Profile,
                                termsAccepted: e.target.checked,
                              })
                            }
                          />
                          <Form.Check.Label className="modal_summaryLabel mr_accept">
                            Accept Terms And Conditions
                          </Form.Check.Label>
                        </Form.Check>
                      </Form>
                    </Col>
                  </Row>

                  <div className="w-100 text-center mt_3">
                    <button
                      className="mint_cnctwallet bodygradientBtn "
                      disabled={loading}
                      onClick={() => onSubmit()}
                    >
                      Submit
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {console.log("accessToken", localStorage.getItem("toksum"))}
                  {localStorage.getItem("toksum") && (
                    <>
                      <div className="w-100 text-center mt_3 hc-margin_bottom">
                        <button
                          className="mint_cnctwallet bodygradientBtn "
                          onClick={() => handleClose()}
                        >
                          Reload
                        </button>
                      </div>
                      <SumsubWebSdk
                        className=""
                        testEnv={false}
                        accessToken={localStorage.getItem("toksum")}
                        expirationHandler={async () => {
                          try {
                            console.log("onexpiration");
                            let token = await createAccessToken();
                            return token;
                          } catch (err) {
                            console.log(err, "onexpiration");
                          }
                        }}
                        config={{
                          lang: "en-us",
                          // email: "test@gmail.com",
                          // phone: "0912234456"
                        }}
                        // options={{ addViewportTag: false, adaptIframeHeight: true }}
                        onMessage={(data, payload) => {
                          console.log("SumsubWebSdk_onMessage", data, payload);
                          if (payload.applicantId) {
                            SetApplicantId(payload.applicantId);
                          }
                        }}
                        onError={(data) => console.log("onError", data)}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default KYCActivate;
